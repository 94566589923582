import React from 'react'
import SEOTools from '../../components/SEOTools'
import { graphql } from 'gatsby'
import { Button, Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { GatsbyImage, getImage, getSrc, StaticImage } from 'gatsby-plugin-image'
import useSmoothScrollTo from '../../hooks/useSmoothScrollTo'
import {
    faChartBar,
    faCheckCircle,
    faCogs,
    faCommentDots,
    faFileAlt,
    faLanguage,
    faMobileAlt,
    faUsersCog,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContactForm from '../../components/Forms/ContactForm'
import Layout from '../../components/Layout'

/**
 * @param data
 * @returns {JSX.Element}
 * @constructor
 */
const SiruSMSPage = ({ data }) => {
    const heroBg = getImage(data.heroBg)
    const checkOutBg = getImage(data.checkOutBg)

    const handleScrollToFeatures = useSmoothScrollTo('features', { offset: -45 })
    const handleScrollToContact = useSmoothScrollTo('contact', { offset: -45 })

    return (
        <Layout>
            <SEOTools
                title='Siru SMS'
                description='Messages that get noticed. Engage with your customers and communicate with impact using mobile messaging.'
                image={getSrc(data.heroBg.childImageSharp)}
            />

            <section className='dark-background-overlay position-relative'>
                <div className='text-white mb-0 d-flex align-items-center full-screen-jumbo'>
                    <Container>
                        <Row>
                            <Col lg={{ span: 6, offset: 1 }} md='8'>
                                <h1 className='name mb-4 text-uppercase'>Siru SMS</h1>
                                <p className='message mb-5 pb-3'>
                                    Messages that <strong>get noticed</strong>
                                </p>
                                <Button
                                    variant='success'
                                    size='lg'
                                    className='px-5 rounded-0'
                                    onClick={handleScrollToContact}>
                                    Talk to sales
                                </Button>
                                <Button
                                    variant='link'
                                    size='lg'
                                    className='px-5 py-3 rounded-0'
                                    onClick={handleScrollToFeatures}>
                                    Read more
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={heroBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='eager'
                    />
                </div>
            </section>

            <section id='features'>
                <Jumbotron fluid>
                    <Container>
                        <Row>
                            <Col lg={{ span: 10, offset: 1 }}>
                                <h2>Features</h2>
                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faUsersCog} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Web portal</p>
                                        <p>Send messages online from an easy to access web portal.</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faMobileAlt} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Masked sender</p>
                                        <p>Determine sender name or phone number.</p>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faCheckCircle} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Validate</p>
                                        <p>Check that a phone number is correct and active.</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faChartBar} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Scalable</p>
                                        <p>A scalable solution to fit different business needs.</p>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faCommentDots} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Message length</p>
                                        <p>
                                            Automatically create longer message threads when 160 characters are
                                            exceeded.
                                        </p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faCogs} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Billing</p>
                                        <p>Option to integrate strong authentication to the payment flow.</p>
                                    </Col>
                                </Row>

                                <Row className='mb-3'>
                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faLanguage} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Encoding</p>
                                        <p>Create messages in any language, length or format.</p>
                                    </Col>

                                    <Col md='1' xs='2' className='pt-1 pl-4'>
                                        <FontAwesomeIcon icon={faFileAlt} size='lg' />
                                    </Col>
                                    <Col md='5' xs='10' className='pl-0'>
                                        <p className='spaced-light mb-2'>Reporting</p>
                                        <p>Monitor your traffic in real-time to confirm delivery.</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Jumbotron>
            </section>

            <section className='darker-background-overlay position-relative'>
                <div className='d-flex text-white text-center mb-0 pb-0'>
                    <Container>
                        <Row className='mt-4 mb-0 pb-0 d-flex justify-content-center'>
                            <Col md='10'>
                                <h2>Engage with your customers in a smarter way</h2>
                                <p className='spaced-light mb-5'>
                                    Siru Mobile offers customized SMS solutions for different business needs. Whether
                                    you want to send single messages or launch a mass campaign we have the tools to help
                                    you.
                                </p>
                                <StaticImage
                                    src='../../images/products/sirusms/sms.png'
                                    alt='logo'
                                    placeholder='blurred'
                                    width={350}
                                />
                            </Col>
                        </Row>
                    </Container>
                    <GatsbyImage
                        className='z-index-n1 position-absolute w-100 h-100'
                        alt=''
                        image={checkOutBg}
                        role='presentation'
                        placeholder='blurred'
                        loading='lazy'
                    />
                </div>
            </section>

            <section id='contact'>
                <Container className='py-5'>
                    <Row className='mt-4 mb-0 pb-0 d-flex justify-content-center'>
                        <Col md='10'>
                            <h2>Contact our sales team</h2>
                            <ContactForm name='sales-contact' />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query {
        heroBg: file(relativePath: { eq: "products/sirusms/sms_hero.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 3840, placeholder: BLURRED, quality: 80, transformOptions: { cropFocus: CENTER })
            }
        }
        checkOutBg: file(relativePath: { eq: "products/sirusms/sirumobile_product_sms.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 3840
                    height: 500
                    placeholder: BLURRED
                    quality: 80
                    transformOptions: { cropFocus: CENTER, fit: OUTSIDE }
                )
            }
        }
    }
`

export default SiruSMSPage
